import React from 'react'
import get from 'lodash/get'
import className from 'classnames'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Link from 'next/link'

import {t} from '@lib/helpers'
import {LazyLoad} from '@lib/LazyLoad'

const DualSlide = dynamic(() => import('@components/DualSlide'))
const ImageLoader = dynamic(() => import('@components/ImageLoader'))
const BlogSection = dynamic(() => import('@components/BlogSection'), {
  ssr: false,
})
const IntroduceSection = dynamic(() => import('@components/IntroduceSection'), {
  ssr: false,
})

const Index = () => {
  return (
    <React.Fragment>
      <Head>
        <link rel='preload' as='image' href='/images/DualSlide--0.webp' />
      </Head>

      <div className='Index'>
        <DualSlide
          height='200vh'
          initValue={[0, -200]}
          conditions={({ratio, tlySet}) => {
            if (ratio <= 0) {
              tlySet([0, -200])
            } else if (ratio > 0 && ratio <= 0.25) {
              tlySet([-100, -100])
            } else if (ratio > 0.25) {
              tlySet([-200, 0])
            }
          }}
        >
          <div className='DualSlide__wrapper DualSlide__wrapper--h1 DualSlide__content--0'>
            <div className='DualSlide__wrapper__content'>
              <h2
                className='DualSlide__h2'
                dangerouslySetInnerHTML={{
                  __html: t('pages.index.dualslide.0.h2'),
                }}
              />

              <p className='DualSlide__p'>{t('pages.index.dualslide.0.p')}</p>

              <div className='Buttons'>
                <a
                  className='DualSlide__a'
                  href='#services'
                  onClick={(e) => {
                    e.preventDefault()

                    window.history.pushState(null, null, '#services')
                    document
                      .querySelector('#services')
                      .scrollIntoView({behavior: 'smooth'})
                  }}
                />
              </div>
            </div>
          </div>

          <div className='DualSlide__image DualSlide__content--1'>
            <ImageLoader
              klass='DualSlide__image__img'
              src='/images/DualSlide--2.jpg'
            />
          </div>

          <div className='DualSlide__image DualSlide__content--2 DualSlide__image--alt'>
            <ImageLoader
              klass='DualSlide__image__img'
              src='/images/DualSlide--1.jpg'
            />
          </div>

          <div
            className={className('DualSlide__wrapper DualSlide__content--3', {
              'DualSlide__wrapper--alt': true,
              'DualSlide__wrapper--green': true,
            })}
          >
            <div className='DualSlide__wrapper__content'>
              <h2
                className='DualSlide__h2'
                dangerouslySetInnerHTML={{
                  __html: t('pages.index.dualslide.1.h2'),
                }}
              />

              <p className='DualSlide__p'>{t('pages.index.dualslide.1.p')}</p>
            </div>
          </div>

          <div
            className={className('DualSlide__wrapper DualSlide__content--4', {
              'DualSlide__wrapper--blue': true,
            })}
          >
            <div className='DualSlide__wrapper__content'>
              <h2
                className='DualSlide__h2'
                dangerouslySetInnerHTML={{
                  __html: t('pages.index.dualslide.2.h2'),
                }}
              />

              <p className='DualSlide__p'>{t('pages.index.dualslide.2.p')}</p>

              <div className='Buttons'>
                <Link href='/services'>
                  <a className='DualSlide__what'>
                    {t('pages.index.dualslide.2.a')}
                  </a>
                </Link>
              </div>
            </div>
          </div>

          <div className='DualSlide__image DualSlide__content--5'>
            <ImageLoader
              klass='DualSlide__image__img'
              src='/images/DualSlide--0.jpg'
              forceActive
            />
          </div>
        </DualSlide>

        <div className='BodySpace BodySpace--no-top'>
          <div className='Anchor' id='services'></div>
          <div className='Index__how'>
            <ImageLoader klass='Index__how__img' src='/images/Index__how.jpg' />

            <div className='container'>
              <h2 className='Index__how__h2'>{t('pages.index.how.h2')}</h2>

              <dl className='Index__how__dl'>
                {[
                  {
                    dt: t('pages.index.how.dl.0.dt'),
                    dd: t('pages.index.how.dl.0.dd'),
                  },
                  {
                    dt: t('pages.index.how.dl.1.dt'),
                    dd: t('pages.index.how.dl.1.dd'),
                  },
                  {
                    dt: t('pages.index.how.dl.2.dt'),
                    dd: t('pages.index.how.dl.2.dd'),
                  },
                  {
                    dt: t('pages.index.how.dl.3.dt'),
                    dd: t('pages.index.how.dl.3.dd'),
                  },
                ].map((x, i) => (
                  <div className='Index__how__dl__item' key={i}>
                    <div className='Index__how__dl__item__wrapper'>
                      <dt className='Index__how__dl__item__dt'>
                        {get(x, 'dt')}
                      </dt>
                      <dd className='Index__how__dl__item__dd'>
                        {get(x, 'dd')}
                      </dd>
                    </div>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          <LazyLoad>
            <IntroduceSection />
          </LazyLoad>

          <div className='Index__clients'>
            <div className='container'>
              <h2 className='Index__clients__h2'>
                {t('pages.index.clients.h2')}
              </h2>

              <div className='Index__clients__gallery'>
                <ul className='Index__clients__gallery__content'>
                  {[
                    {
                      key: 'jsw',
                      width: '171px',
                    },
                    {
                      key: 'agoda',
                      width: '136px',
                    },
                    {
                      key: 'homepro',
                      width: '166px',
                    },
                    {
                      key: 'scb',
                      width: '137px',
                    },
                    // {
                    //   key: 'bitkub',
                    //   width: '209px',
                    // },
                    {
                      key: 'aka',
                      width: '207px',
                    },
                    {
                      key: 'zen',
                      width: '121px',
                    },
                    {
                      key: 'onthetable',
                      width: '140px',
                    },
                    {
                      key: 'pioneerthailand',
                      width: '182px',
                    },
                    {
                      key: 'aphaiphubet',
                      width: '164px',
                    },
                    {
                      key: 'mezzo',
                      width: '110px',
                    },
                    {
                      key: 'cotto',
                      width: '140px',
                    },
                    {
                      key: 'techsauce',
                      width: '164px',
                    },
                    {
                      key: 'atimedesign',
                      width: '140px',
                    },
                    {
                      key: 'tog',
                      width: '140px',
                    },
                    {
                      key: 'decr',
                      width: '133px',
                    },
                    {
                      key: 'zortout',
                      width: '189px',
                    },
                    {
                      key: 'denso',
                      width: '174px',
                    },
                    {
                      key: 'c-vitt',
                      width: '166px',
                    },
                    {
                      key: 'visai',
                      width: '181px',
                    },
                    {
                      key: 'kubota',
                      width: '216px',
                    },
                    {
                      key: 'arincare',
                      width: '318px',
                    },
                    {
                      key: 'gq',
                      width: '126px',
                    },
                    {
                      key: 'gat',
                      width: '110px',
                    },
                    {
                      key: 'globish',
                      width: '245px',
                    },
                    {
                      key: 'voguebeauty',
                      width: '151px',
                    },
                    {
                      key: 'vogue',
                      width: '249px',
                    },
                  ].map(({key, width, height}, i) => (
                    <li
                      key={i}
                      style={{width: `${width ?? '148px'} !important`}}
                    >
                      <ImageLoader
                        src={`/images/Index__clients__gallery__content__item--${key}.png`}
                        srcSet={`/images/Index__clients__gallery__content__item--${key}--2x.png 2x`}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className='Index__why'>
            <div className='Index__why__wrapper'>
              <div className='container'>
                <div className='Index__why__left'>
                  <ImageLoader
                    klass='Index__why__wrapper__img'
                    src='/images/Index__why.jpg'
                  />

                  <p className='Index__why__p'>{t('pages.index.why.p')}</p>
                </div>

                <div className='Index__why__right'>
                  <h2 className='Index__why__right__h2'>
                    {t('pages.index.why.h2')}
                  </h2>

                  <dl className='Index__why__right__dl'>
                    {[
                      {
                        dt: t('pages.index.why.dl.0.dt'),
                        dd: [
                          t('pages.index.why.dl.0.dd.0'),
                          t('pages.index.why.dl.0.dd.1'),
                          t('pages.index.why.dl.0.dd.2'),
                        ],
                      },
                      {
                        dt: t('pages.index.why.dl.1.dt'),
                        dd: [
                          t('pages.index.why.dl.1.dd.0'),
                          t('pages.index.why.dl.1.dd.1'),
                        ],
                      },
                      {
                        dt: t('pages.index.why.dl.2.dt'),
                        dd: [
                          t('pages.index.why.dl.2.dd.0'),
                          t('pages.index.why.dl.2.dd.1'),
                          t('pages.index.why.dl.2.dd.2'),
                        ],
                      },
                      {
                        dt: t('pages.index.why.dl.3.dt'),
                        dd: [
                          t('pages.index.why.dl.3.dd.0'),
                          t('pages.index.why.dl.3.dd.1'),
                          t('pages.index.why.dl.3.dd.2'),
                          t('pages.index.why.dl.3.dd.3'),
                        ],
                      },
                    ].map((x, i) => (
                      <div className='Index__why__right__dl__item' key={i}>
                        <div className='Index__why__right__dl__item__wrapper'>
                          <dt className='Index__why__right__dl__item__dt'>
                            {get(x, 'dt')}
                          </dt>

                          <ul className='Index__why__right__dl__item__ul'>
                            {get(x, 'dd', []).map((y, i) => (
                              <li
                                key={i}
                                className='Index__why__right__dl__item__ul__li'
                                dangerouslySetInnerHTML={{__html: y}}
                              />
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>

            <ImageLoader
              klass='Index__why__img'
              src='/images/Index__why__img.jpg'
            />
          </div>

          <LazyLoad>
            <BlogSection />
          </LazyLoad>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Index
